import { getSearchAppControllers } from '../searchAppController';
import { EditorAppContext } from '../types';
import { Interaction } from '../constants/interaction';
import {
  removeController,
  removeControllerConnectedComponents,
} from '../editorSDKHelpers';

export async function onRemoveApp(appContext: EditorAppContext) {
  const { fedops, reportError } = appContext;
  fedops.interactionStarted(Interaction.RemoveApp);

  const controllerRefs = await getSearchAppControllers(appContext);

  for (const controllerRef of controllerRefs) {
    try {
      await removeControllerConnectedComponents(appContext, controllerRef);
    } catch (error) {
      reportError(error);
    }

    try {
      await removeController(appContext, controllerRef);
    } catch (error) {
      reportError(error);
    }
  }

  await saveEditor(appContext);
  fedops.interactionEnded(Interaction.RemoveApp);
}

async function saveEditor(appContext: EditorAppContext) {
  const { editorSDK, reportError, appDefinitionId } = appContext;

  try {
    await editorSDK.editor.save(appDefinitionId);
  } catch (error) {
    reportError(error);
  }
}
